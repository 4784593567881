import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-profile',
  templateUrl: './login-profile.component.html',
  styleUrls: ['./login-profile.component.scss']
})
export class LoginProfileComponent implements OnInit {

  userM:any;
  user_id:string;
  imageURL= this.cookieService.get('imageURL');
  showSpinner: boolean = true;

  constructor(public ApiService: ApiService,private router: Router ,private cookieService: CookieService  ) { 

    this.user_id= this.cookieService.get('user_id');

  }

  ngOnInit() {
    this.user_id= this.cookieService.get('user_id');

    this.ApiService.show_AdminUsers(this.user_id).subscribe( 
       data=>{
         let resources:any=data["admin_data"];
       this.userM = resources;
       this.showSpinner=false;
     });
  }
   
}
