import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private cookieService: CookieService) {
  }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  // Base url
  baseurl = 'http://pharmacyapi.e-wasfa.com/api';



  // Http Headers 
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'


    })
  }
  /**********************************************login*********************************************/

  // POST
  login(email,passwords): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('email', email);
    formData.append('passwords', passwords);

    return this.http.post<any>(this.baseurl + '/admin_login', formData)
      .pipe(

        retry(1),
        catchError(this.errorHandl)
      )
  }

  // POST
  update_adminProfile(user_id: string, fileToUpload: File, first_name: string,last_name:string,phone:string, email: string, passwords: string): Observable<any> {


    const formData: FormData = new FormData();
    if(fileToUpload!=null){
      formData.append('image', fileToUpload, fileToUpload.name);

    }

    


    formData.append('user_id', user_id);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('passwords', passwords);


    return this.http.post<any[]>(this.baseurl + '/update_adminProfile', formData)

  }


    // POST
    add_user( fileToUpload: File, first_name: string,last_name:string, passwords: string,phone:string, email: string,group_id: string): Observable<any> {


      const formData: FormData = new FormData();
      if(fileToUpload!=null){
        formData.append('image', fileToUpload, fileToUpload.name);
  
      }
  
      formData.append('first_name', first_name);
      formData.append('last_name', last_name);
      formData.append('passwords', passwords);

      formData.append('phone', phone);
      formData.append('email', email);
  
      formData.append('group_id', group_id);

      return this.http.post<any[]>(this.baseurl + '/add_user', formData)
  
    }
  

        // POST
        update_user( id:string,fileToUpload: File, first_name: string,last_name:string, passwords: string,phone:string, email: string,group_id: string): Observable<any> {


          const formData: FormData = new FormData();
          if(fileToUpload!=null){
            formData.append('image', fileToUpload, fileToUpload.name);
      
          }
          formData.append('id', id);

          formData.append('first_name', first_name);
          formData.append('last_name', last_name);
          formData.append('passwords', passwords);
    
          formData.append('phone', phone);
          formData.append('email', email);
      
          formData.append('group_id', group_id);
    
          return this.http.post<any[]>(this.baseurl + '/update_user', formData)
      
        }


  // GET
  count_data(): Observable<any[]> {


    return this.http.get<any[]>(this.baseurl + '/count_data')
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }


  // GET
  show_AdminUsers(user_id): Observable<any> {


    return this.http.get<any>(this.baseurl + '/show_AdminUsers?user_id=' + user_id)
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }




   // delete
   delete_offer(offer_id): Observable<any[]> {

    return this.http.get<any[]>(this.baseurl + '/delete_offer?offer_id=' + offer_id)
      .pipe(
        retry(1),
        catchError(this.errorHandl)
      )
  }



 


       // Delete
       block_user(user_id): Observable<any[]> {


        return this.http.get<any[]>( this.baseurl + '/block_user?user_id=' + user_id)
          .pipe(
            retry(1),
            catchError(this.errorHandl)
          )
      }


      

       // Delete
       delete_admin(user_id): Observable<any[]> {


        return this.http.get<any[]>( this.baseurl + '/delete_admin?admin_id=' + user_id)
          .pipe(
            retry(1),
            catchError(this.errorHandl)
          )
      }





  



    // POST
    make_offer(fileToUpload: File, arabic_name: string,english_name:string,offer_type:string,price_before:string,offer_start:string,offer_end:string,offer_price:string,offer_arrange:string): Observable<any[]> {

  const formData: FormData = new FormData();
  if(fileToUpload!=null){
  formData.append('image', fileToUpload, fileToUpload.name);
  }
  formData.append('arabic_name', arabic_name);
  formData.append('english_name', english_name);
  formData.append('offer_type', offer_type);
  formData.append('price_before', price_before);
  formData.append('offer_start', offer_start);
  formData.append('offer_end', offer_end);
  formData.append('offer_price', offer_price);
  formData.append('offer_arrange', offer_arrange);





  return this.http.post<any[]>(this.baseurl + '/make_offer', formData)


}


    // POST
    update_orderPrice(order_id:number, price: string,comment:string): Observable<any[]> {

      const formData: FormData = new FormData();
      formData.append('order_id', order_id.toString());

      formData.append('price', price);
      formData.append('comment', comment);
     
      return this.http.post<any[]>(this.baseurl + '/update_orderPrice', formData)
    
    
    }



    

     

      
           
      




    // GET
    show_doctoruser(): Observable<any[]> {


      return this.http.get<any[]>(this.baseurl + '/show_doctoruser')
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }


       // GET
       show_waitOrders(): Observable<any[]> {


        return this.http.get<any[]>(this.baseurl + '/show_waitOrders')
          .pipe(
            retry(1),
            catchError(this.errorHandl)
          )
      }


           // GET
           all_orders(): Observable<any[]> {


            return this.http.get<any[]>(this.baseurl + '/all_orders')
              .pipe(
                retry(1),
                catchError(this.errorHandl)
              )
          }

                // GET
                search_waitorders(user_name,doctor_name,cityname,delivery,branch,datefrom,dateto): Observable<any[]> {


                  console.log("ay7aga",user_name,doctor_name,cityname,delivery,branch,datefrom,dateto)

         
                  user_name = user_name == null ?"":user_name;

                  doctor_name = doctor_name == null ?"":doctor_name;

                  cityname = cityname == null ?"":cityname;

                  delivery = delivery == null ?"":delivery;
                  branch = branch == null ?"":branch;


                  datefrom = datefrom == null ?"":datefrom;

                  dateto = dateto == null ?"":dateto;

                  return this.http.get<any[]>(this.baseurl + '/search_waitorders?user_name=' + user_name + '&doctor_name=' + doctor_name + '&cityname=' + cityname + '&delivery=' + delivery + '&branch=' + branch + '&datefrom=' + datefrom + '&dateto=' + dateto )
                  
                   }

                   
              
              


                                  

                                  


                
                


             // GET
             show_acceptOrders(): Observable<any[]> {


              return this.http.get<any[]>(this.baseurl + '/show_acceptOrders')
                .pipe(
                  retry(1),
                  catchError(this.errorHandl)
                )
            }


                   // GET
                   show_receivedOrders(): Observable<any[]> {


        return this.http.get<any[]>(this.baseurl + '/show_receivedOrders')
          .pipe(
            retry(1),
            catchError(this.errorHandl)
          )
      }


             // GET
             show_refusedOrders(): Observable<any[]> {


              return this.http.get<any[]>(this.baseurl + '/show_refusedOrders')
                .pipe(
                  retry(1),
                  catchError(this.errorHandl)
                )
            }


                // GET
                show_neglectOrders(): Observable<any[]> {


                  return this.http.get<any[]>(this.baseurl + '/show_neglectOrders')
                    .pipe(
                      retry(1),
                      catchError(this.errorHandl)
                    )
                }


       // GET
       show_Patientuser(): Observable<any[]> {


        return this.http.get<any[]>(this.baseurl + '/show_Patientuser')
          .pipe(
            retry(1),
            catchError(this.errorHandl)
          )
      }

          // GET
          show_admins(): Observable<any[]> {


            return this.http.get<any[]>(this.baseurl + '/show_admins')
              .pipe(
                retry(1),
                catchError(this.errorHandl)
              )
          }
    

            // GET
            show_clinicOffers(): Observable<any[]> {


              return this.http.get<any[]>(this.baseurl + '/show_clinicOffers')
                .pipe(
                  retry(1),
                  catchError(this.errorHandl)
                )
            }

                  // GET
                  show_pharmacyoffers(): Observable<any[]> {


                    return this.http.get<any[]>(this.baseurl + '/show_pharmacyoffers')
                      .pipe(
                        retry(1),
                        catchError(this.errorHandl)
                      )
                  }
              
        

                  
    edit_doctorState(user_id): Observable<any> {
    return this.http.get(this.baseurl + '/edit_doctorState?user_id='+user_id);

  }


  user_orders(user_id): Observable<any> {
    return this.http.get(this.baseurl + '/user_orders?user_id='+user_id);

    
  }


  
  user_address(user_id): Observable<any> {
    return this.http.get(this.baseurl + '/user_address?user_id='+user_id);

  }



  edit_userState(user_id): Observable<any> {
    return this.http.get(this.baseurl + '/edit_userState?user_id='+user_id);

  }
  





    // POST
    update_offer(offer_id: number, fileToUpload: File, arabic_name: string,english_name:string,offer_type:string,price_before:string,offer_start:string,offer_end:string,offer_price:string,offer_arrange:string): Observable<any[]> {

      const formData: FormData = new FormData();
  
      if(fileToUpload!=null){
        formData.append('image', fileToUpload, fileToUpload.name);
  
      }
      formData.append('offer_id', offer_id.toString());
       formData.append('arabic_name', arabic_name);
       formData.append('english_name', english_name);
      formData.append('offer_type', offer_type);
      formData.append('price_before', price_before);
      formData.append('offer_start', offer_start);
      formData.append('offer_end', offer_end);
      formData.append('offer_price', offer_price);
      formData.append('offer_arrange', offer_arrange);
    
   
  
      return this.http.post<any[]>(this.baseurl + '/update_offer', formData)
  
  
    }

 



 




    

     

       



           // GET
           show_orderById(order_id): Observable<any[]> {

            return this.http.get<any[]>(this.baseurl + '/show_orderById?order_id=' + order_id)
              .pipe(
                retry(1),
                catchError(this.errorHandl)
              )
          }

                // GET
                change_orderstate(order_id): Observable<any[]> {

                  return this.http.get<any[]>(this.baseurl + '/change_orderstate?order_id=' + order_id)
                    .pipe(
                      retry(1),
                      catchError(this.errorHandl)
                    )
                }

                      // GET
                      change_acceptorder(order_id): Observable<any[]> {

                        return this.http.get<any[]>(this.baseurl + '/change_acceptorder?order_id=' + order_id)
                          .pipe(
                            retry(1),
                            catchError(this.errorHandl)
                          )
                      }
           
     
 


     // GET
     show_offerByid(offer_id): Observable<any> {
      return this.http.get<any>(this.baseurl + '/show_offerByid?offer_id='+ offer_id)
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }
     
        

               // GET
               show_cities(): Observable<any[]> {

                return this.http.get<any[]>(this.baseurl + '/show_cities')
                  .pipe(
                    retry(1),
                    catchError(this.errorHandl)
                  )
              }

              
               // GET
               show_delivery(): Observable<any[]> {

                return this.http.get<any[]>(this.baseurl + '/show_delivery')
                  .pipe(
                    retry(1),
                    catchError(this.errorHandl)
                  )
              }


                   // GET
                   show_roles(): Observable<any[]> {

                    return this.http.get<any[]>(this.baseurl + '/show_roles')
                      .pipe(
                        retry(1),
                        catchError(this.errorHandl)
                      )
                  }

                           // GET
                           show_branches(): Observable<any[]> {

                            return this.http.get<any[]>(this.baseurl + '/show_branches')
                              .pipe(
                                retry(1),
                                catchError(this.errorHandl)
                              )
                          }


                       // GET
                       show_allcopon(): Observable<any[]> {

                        return this.http.get<any[]>(this.baseurl + '/show_allcopon')
                          .pipe(
                            retry(1),
                            catchError(this.errorHandl)
                          )
                      }



                   // GET
                   show_about(): Observable<any> {

                    return this.http.get<any>(this.baseurl + '/show_about')
                      .pipe(
                        retry(1),
                        catchError(this.errorHandl)
                      )
                  }
  
    // delete
    delete_cities(city_id): Observable<any[]> {

      return this.http.get<any[]>(this.baseurl + '/delete_cities?city_id=' + city_id)
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

       // delete
       delete_branches(id): Observable<any[]> {

        return this.http.get<any[]>(this.baseurl + '/delete_branches?id=' + id)
          .pipe(
            retry(1),
            catchError(this.errorHandl)
          )
      }


     
    // delete
    delete_copon(copon_id): Observable<any[]> {

      return this.http.get<any[]>(this.baseurl + '/delete_copon?copon_id=' + copon_id)
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }


            // POST
            insert_city(arabic_name: string,english_name:string,longitude:string,latitude:string): Observable<any[]> {

              const formData: FormData = new FormData();
               formData.append('arabic_name', arabic_name);
               formData.append('english_name', english_name);

               formData.append('longitude', longitude);
               formData.append('latitude', latitude);

        
              return this.http.post<any[]>(this.baseurl + '/insert_city', formData)
          
          
            }

                // POST
                insert_notification(message: string,state:string): Observable<any[]> {

                  const formData: FormData = new FormData();
                   formData.append('message', message);
                   formData.append('state', state);
    
    
            
                  return this.http.post<any[]>(this.baseurl + '/insert_notification', formData)
              
              
                }


                   // POST
                   insert_branches(arabic_name: string,english_name:string,address:string,longitude:string,latitude:string): Observable<any[]> {

                    const formData: FormData = new FormData();
                     formData.append('arabic_name', arabic_name);
                     formData.append('english_name', english_name);
                     formData.append('address', address);
                     formData.append('longitude', longitude);
                     formData.append('latitude', latitude);
      
              
                    return this.http.post<any[]>(this.baseurl + '/insert_branches', formData)
                
                
                  }

                


                   // POST
                   insert_copon(code: string,value:string,start_date:string,end_date:string): Observable<any[]> {

                    const formData: FormData = new FormData();
                     formData.append('code', code);
                     formData.append('value', value);
                     formData.append('start_date', start_date);
      
                     formData.append('end_date', end_date);

                    return this.http.post<any[]>(this.baseurl + '/insert_copon', formData)
                
                
                  }
      

                  // GET
                  show_cityByid(city_id): Observable<any> {
                    return this.http.get<any>(this.baseurl + '/show_cityByid?city_id='+ city_id)
                      .pipe(
                        retry(1),
                        catchError(this.errorHandl)
                      )
                  }


                      // GET
                      show_notificationByid(message_id): Observable<any> {
                        return this.http.get<any>(this.baseurl + '/show_notificationByid?message_id='+ message_id)
                          .pipe(
                            retry(1),
                            catchError(this.errorHandl)
                          )
                      }


                     // GET
                     show_branchesByid(branche_id): Observable<any> {
                      return this.http.get<any>(this.baseurl + '/show_branchesByid?branche_id='+ branche_id)
                        .pipe(
                          retry(1),
                          catchError(this.errorHandl)
                        )
                    }

                     // GET
                     show_coponByid(copon_id): Observable<any> {
                      return this.http.get<any>(this.baseurl + '/show_coponByid?copon_id='+ copon_id)
                        .pipe(
                          retry(1),
                          catchError(this.errorHandl)
                        )
                    }
  

                         // POST
                         edit_about(fileToUpload:File,a_title: string,E_title:string,description:string,address:string,email:string,phone:string): Observable<any[]> {

              const formData: FormData = new FormData();
              if(fileToUpload!=null){
                formData.append('image', fileToUpload, fileToUpload.name);
          
              }
               formData.append('a_title', a_title);
               formData.append('E_title', E_title);

               formData.append('description', description);
               formData.append('address', address);
               formData.append('email', email);
               formData.append('phone', phone);

        
              return this.http.post<any[]>(this.baseurl + '/edit_about', formData)
          
          
            }
        
     // POST
     update_city( arabic_name: string,english_name:string, city_id: string,longitude:string,latitude:string): Observable<any[]> {

      const formData: FormData = new FormData();
      formData.append('arabic_name', arabic_name);
      formData.append('english_name', english_name);

      formData.append('city_id', city_id.toString());
    
      formData.append('longitude', longitude);
      formData.append('latitude', latitude);
  
  
      return this.http.post<any[]>(this.baseurl + '/update_city', formData)
  
  
    }


      // POST
      update_notification( message: string,E_message:string,message_id:string): Observable<any[]> {

        const formData: FormData = new FormData();
        formData.append('message', message);
        formData.append('E_message', E_message);
        formData.append('message_id', message_id.toString());
          return this.http.post<any[]>(this.baseurl + '/update_notification', formData)
    
      }

           
     // POST
     update_branches(id: string, arabic_name: string,english_name:string,address:string, longitude:string,latitude:string): Observable<any[]> {

      const formData: FormData = new FormData();
      formData.append('id', id.toString());

      formData.append('arabic_name', arabic_name);
      formData.append('english_name', english_name);
      formData.append('address', address);

    
      formData.append('longitude', longitude);
      formData.append('latitude', latitude);
  
  
      return this.http.post<any[]>(this.baseurl + '/update_branches', formData)
  
  
    }

        // POST
        update_copon( code: string, copon_id: string,value:string,start_date:string,end_date:string): Observable<any[]> {

          const formData: FormData = new FormData();
          formData.append('code', code);
          formData.append('copon_id', copon_id.toString());
        
          formData.append('value', value);
          formData.append('start_date', start_date);
          formData.append('end_date', end_date);

      
          return this.http.post<any[]>(this.baseurl + '/update_copon', formData)
      
      
        }


    // GET
    show_contacts(): Observable<any[]> {
      return this.http.get<any[]>(this.baseurl + '/show_contacts')
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }


        // GET
        show_allnotification(): Observable<any[]> {
          return this.http.get<any[]>(this.baseurl + '/show_allnotification')
            .pipe(
              retry(1),
              catchError(this.errorHandl)
            )
        }

           // delete
           delete_contactUSs(contact_id): Observable<any[]> {

            return this.http.get<any[]>(this.baseurl + '/delete_contact?contact_id=' + contact_id)
              .pipe(
                retry(1),
                catchError(this.errorHandl)
              )
          }


                 // delete
                 delete_notification(message_id): Observable<any[]> {

                  return this.http.get<any[]>(this.baseurl + '/delete_notification?message_id=' + message_id)
                    .pipe(
                      retry(1),
                      catchError(this.errorHandl)
                    )
                }
              
                    // GET
                    show_allUser(): Observable<any[]> {
      return this.http.get<any[]>(this.baseurl + '/show_allUser')
        .pipe(
          retry(1),
          catchError(this.errorHandl)
        )
    }

        

                             // POST
                             send_notification_All_patient(body: string): Observable<any[]> {

                              const formData: FormData = new FormData();
                             
                               formData.append('body', body);
                          
                        
                              return this.http.post<any[]>(this.baseurl + '/send_notification_All_patient', formData)
                          
                          
                            }

                                   // POST
                                   send_notification_All_doctor(body: string): Observable<any[]> {

                                    const formData: FormData = new FormData();
                                   
                                     formData.append('body', body);
                                
                              
                                    return this.http.post<any[]>(this.baseurl + '/send_notification_All_doctor', formData)
                                
                                
                                  }

                                      // POST
                                      send_notification_for_spacialUser(body: string,user_id:string): Observable<any[]> {

                                        const formData: FormData = new FormData();
                                       
                                         formData.append('body', body);
                                    
                                         formData.append('user_id', user_id);

                                        return this.http.post<any[]>(this.baseurl + '/send_notification_for_spacialUser', formData)
                                    
                                    
                                      }


                                      
                                      // POST
                                      send_notification_All_usersinCity(body: string,city:string): Observable<any[]> {

                                        const formData: FormData = new FormData();
                                       
                                         formData.append('body', body);
                                    
                                         formData.append('city', city);

                                        return this.http.post<any[]>(this.baseurl + '/send_notification_All_usersinCity', formData)
                                    
                                    
                                      }
                                      Mon_Monthchart(): Observable<any[]> {


                                        return this.http.get<any[]>( this.baseurl + '/Mon_Monthchart')
                                          .pipe(
                                            retry(1),
                                            catchError(this.errorHandl)
                                          )
                                      }
                                      
                                      order_Monthchart(): Observable<any[]> {
                                      
                                      
                                        return this.http.get<any[]>( this.baseurl + '/order_Monthchart')
                                          .pipe(
                                            retry(1),
                                            catchError(this.errorHandl)
                                          )
                                      }
                                      ///////////////////////////////////////




                                            // POST
    search_waitorders2( user_name:string, doctor_name: string,cityname:string, delivery: string,branch:string, datefrom: string,dateto: string): Observable<any> {


          const formData: FormData = new FormData();
       
          formData.append('user_name', user_name);
          formData.append('doctor_name', doctor_name);
          formData.append('cityname', cityname);
          formData.append('delivery', delivery);
          formData.append('branch', branch);
          formData.append('datefrom', datefrom);
          formData.append('dateto', dateto);
    
          return this.http.post<any[]>(this.baseurl + '/search_waitorders2', formData)
      
        }


        search_acceptorders( user_name:string, doctor_name: string,cityname:string, delivery: string,branch:string, datefrom: string,dateto: string): Observable<any> {


          const formData: FormData = new FormData();
       
          formData.append('user_name', user_name);
          formData.append('doctor_name', doctor_name);
          formData.append('cityname', cityname);
          formData.append('delivery', delivery);
          formData.append('branch', branch);
          formData.append('datefrom', datefrom);
          formData.append('dateto', dateto);
    
          return this.http.post<any[]>(this.baseurl + '/search_acceptorders', formData)
      
        }


        search_receiveorders( user_name:string, doctor_name: string,cityname:string, delivery: string,branch:string, datefrom: string,dateto: string): Observable<any> {


          const formData: FormData = new FormData();
       
          formData.append('user_name', user_name);

          formData.append('doctor_name', doctor_name);
          formData.append('cityname', cityname);
          formData.append('delivery', delivery);
          formData.append('branch', branch);
          formData.append('datefrom', datefrom);
          formData.append('dateto', dateto);
    
          return this.http.post<any[]>(this.baseurl + '/search_receiveorders', formData)
      
        }


        
        search_refusedorders( user_name:string, doctor_name: string,cityname:string, delivery: string,branch:string, datefrom: string,dateto: string): Observable<any> {


          const formData: FormData = new FormData();
       
          formData.append('user_name', user_name);

          formData.append('doctor_name', doctor_name);
          formData.append('cityname', cityname);
          formData.append('delivery', delivery);
          formData.append('branch', branch);
          formData.append('datefrom', datefrom);
          formData.append('dateto', dateto);
    
          return this.http.post<any[]>(this.baseurl + '/search_refusedorders', formData)
      
        }

        
        search_neglectorders( user_name:string, doctor_name: string,cityname:string, delivery: string,branch:string, datefrom: string,dateto: string): Observable<any> {


          const formData: FormData = new FormData();
       
          formData.append('user_name', user_name);
          formData.append('doctor_name', doctor_name);
          formData.append('cityname', cityname);
          formData.append('delivery', delivery);
          formData.append('branch', branch);
          formData.append('datefrom', datefrom);
          formData.append('dateto', dateto);
    
          return this.http.post<any[]>(this.baseurl + '/search_neglectorders', formData)
      
        }
  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
