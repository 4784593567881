import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  user_id:string;
  group_id:string;

  showSpinner: boolean = false;


  constructor(public ApiService: ApiService,private router: Router,private cookieService: CookieService) { 

  
  } 

  ngOnInit() {

  }


  submitForm(email,passwords){
    this.showSpinner=true;

    this.ApiService.login(email.value,passwords.value).subscribe(
      data=>  {

        let error:number= data["error"];

        if(error==0){

        this.router.navigate(['/dashboard'])

        }else{
          alert("معلومات المستخدم خطأ");
          this.showSpinner=false;

        }
        let res:any= data.admin_data;
        this.user_id= res["id"];
        this.group_id= res["group_id"];

        this.cookieService.set('user_id', this.user_id );
        this.cookieService.set('group_id', this.group_id );

        this.ngOnInit();
        console.log(this.user_id)

      }

    );
  }



}
