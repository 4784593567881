import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './LoginF/login/login.component';
import { LoginProfileComponent } from './LoginF/login-profile/login-profile.component';
import { EditProfileComponent } from './LoginF/edit-profile/edit-profile.component';
import { AuthGuardService } from "./auth-guard.service";

const routes: Routes =[
  { path: 'login',component: LoginComponent },
  { path: 'LoginProfile',component: LoginProfileComponent  ,canActivate: [AuthGuardService]},
  { path: 'EditProfile',component: EditProfileComponent,canActivate: [AuthGuardService] },

  { 
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuardService]
  },
   {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }]},
  {
    path: '**',
    redirectTo: 'dashboard',
    canActivate: [AuthGuardService]

  },
  

  
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
