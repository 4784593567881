import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './LoginF/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginProfileComponent } from './LoginF/login-profile/login-profile.component';
import { EditProfileComponent } from './LoginF/edit-profile/edit-profile.component';

import { CookieService } from 'ngx-cookie-service';
import { AuthGuardService } from "./auth-guard.service";


import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';


























@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    BrowserModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),AutocompleteLibModule

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    LoginProfileComponent,
    EditProfileComponent,
    

   
  
   

  
 
   
    

   

  
  

   
  

 

  
 
   
   
 
    
      ],
  providers: [ CookieService,AuthGuardService ],

  bootstrap: [AppComponent]
})
export class AppModule { }
