import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  userM:any;
  user_id:string;

  selectedImage:File=null;
  ImageURl:string="./assets/img/logo.png";
  imageURL= this.cookieService.get('imageURL');
  showSpinner: boolean = true;

  constructor(public ApiService: ApiService,private router: Router ,private cookieService: CookieService  ) { 

    this.user_id= this.cookieService.get('user_id');

  }

  handlefileInput(file:FileList){
    this.selectedImage =file.item(0);
    var reader=new FileReader();
    reader.onload=(event:any)=>{
      this.ImageURl=event.target.result;
    }
    reader.readAsDataURL(this.selectedImage);
  }


  ngOnInit() {
    this.user_id= this.cookieService.get('user_id');

     this.ApiService.show_AdminUsers(this.user_id).subscribe( 
    data=>{
      let resources:any=data["admin_data"];

    this.userM = resources;
    this.showSpinner=false;

     });
  }

  submitForm(first_name,last_name,phone,email,passwords) {
    this.showSpinner=true;

    this.user_id= this.cookieService.get('user_id');

     this.ApiService.update_adminProfile(this.user_id,this.selectedImage,first_name.value,last_name.value,phone.value,email.value,passwords.value).subscribe((reponse)=>{
       alert("تم التعديل بنجاح ");
       this.router.navigate(['/LoginProfile'])

    }); 
 
   
   }
   
}
