import { Component, OnInit } from '@angular/core';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

declare interface RouteInfo {
    path?: string;
    title?: string;
    icon?: string;
    class?: string;
}
export const ROUTES: RouteInfo[] = [
   { path: '/dashboard', title: 'لوحة التحكم',  icon: 'design_app', class: 'sidefont' },
   { path: '/users', title: ' الأطباء',  icon:'users_single-02 ', class: 'sidefont' },
   { path: '/teachers', title: 'العملاء ',  icon:'business_badge', class: 'sidefont' },
   { path: '/orders', title: 'الطلبات ',  icon:'objects_support-17 ', class: 'sidefont' },

   { path: '/orderb', title: 'الطلباتb ',  icon:'objects_support-17 ', class: 'sidefont' },

   { path: '/cobon', title: 'اكواد خصم ',  icon:'gestures_tap-01', class: 'sidefont' },
   { path: '/branche', title: 'الفروع',  icon:'business_globe', class: 'sidefont' },
   { path: '/Vmessage', title: 'الرسائل الافتراضية',  icon:'files_single-copy-04', class: 'sidefont' },

    { path: '/offer', title: ' العروض',  icon:'business_globe', class: 'sidefont' },
    { path: '/city', title: 'المدن ',  icon:'location_pin', class: 'sidefont' },
    { path: '/notification', title: 'الرسائل',  icon:'files_single-copy-04', class: 'sidefont' },

    { path: '/about', title: ' من نحن',  icon:'business_bulb-63', class: 'sidefont' },

    
     //{ path: '/allorders', title: ' كل الطلبات ',  icon:'users_single-02 ', class: 'sidefont' },

];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  group_id= this.cookieService.get('group_id');
    constructor(private cookieService:CookieService) { }

  i:string=this.group_id;
  ngOnInit() {
       if(this.i=='1'){

      const ROUTES2: RouteInfo[] = [
        { path: '/dashboard', title: 'لوحة التحكم',  icon: 'design_app', class: 'sidefont' },
        { path: '/users', title: ' الأطباء',  icon:'users_single-02 ', class: 'sidefont' },
        { path: '/teachers', title: 'العملاء ',  icon:'business_badge', class: 'sidefont' },
        { path: '/ordersb', title: 'الطلبات',  icon:'objects_support-17 ', class: 'sidefont' },

        { path: '/cobon', title: 'اكواد خصم ',  icon:'gestures_tap-01', class: 'sidefont' },
        { path: '/branche', title: 'الفروع',  icon:'business_globe', class: 'sidefont' },
        { path: '/Vmessage', title: 'الرسائل الافتراضية',  icon:'files_single-copy-04', class: 'sidefont' },
     
         { path: '/offer', title: ' العروض',  icon:'business_globe', class: 'sidefont' },
         { path: '/city', title: 'المدن ',  icon:'location_pin', class: 'sidefont' },
         { path: '/notification', title: 'الرسائل',  icon:'files_single-copy-04', class: 'sidefont' },
     
         { path: '/about', title: ' من نحن',  icon:'business_bulb-63', class: 'sidefont' },    
         { path: '/admins', title: ' admins',  icon:'users_single-02 ', class: 'sidefont' },

     ];
      this.menuItems = ROUTES2.filter(Title => Title);
    }

    if(this.i=='2'){
      const ROUTES2: RouteInfo[] = [
        { path: '/dashboard', title: 'لوحة التحكم',  icon: 'design_app', class: 'sidefont' },

        { path: '/users', title: ' الأطباء',  icon:'users_single-02 ', class: 'sidefont' },
        { path: '/teachers', title: 'العملاء ',  icon:'business_badge', class: 'sidefont' },     
     ];
      this.menuItems = ROUTES2.filter(Title => Title);
    }

    else if(this.i=='3'){
      const ROUTES2: RouteInfo[] = [
        { path: '/dashboard', title: 'لوحة التحكم',  icon: 'design_app', class: 'sidefont' },
        { path: '/orders', title: 'الطلبات ',  icon:'objects_support-17 ', class: 'sidefont' },
     
     ];
      this.menuItems = ROUTES.filter(Title => Title);
    }
    else if(this.i=='4'){
      const ROUTES2: RouteInfo[] = [
        { path: '/dashboard', title: 'لوحة التحكم',  icon: 'design_app', class: 'sidefont' },

     
     ];
      this.menuItems = ROUTES.filter(Title => Title);
    }
 

  
  }
  isMobileMenu() {
      if ( window.innerWidth > 991) {
          return false;
      }
      return true;
  };
}
