import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private cookieService: CookieService) { }

  ngOnInit() {
    this.cookieService.set( 'imageURL', 'http://pharmacyapi.e-wasfa.com/' );

  }
 



 
}
